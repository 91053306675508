export enum InquiryButtonTypeName {
    OFFER = "ZAPYTAJ O OFERTĘ",
    PRICE = "ZAPYTAJ O CENĘ",
    PRICES = "ZAPYTAJ O CENY",
    RESERVATION = "Rezerwacja",
    ARRANGE_MEETING = "UMÓW SIĘ NA SPOTKANIE",
    INVESTMENT = "ZAPYTAJ DEWELOPERA O OFERTĘ",
    PROMOTIONS = "KONTAKT Z DEWELOPEREM",
    VENDOR = "ZAPYTAJ DEWELOPERA"
}
export enum InquiryButtonTypeName {
    OFFER = "ZAPYTAJ O OFERTĘ",
    PRICE = "ZAPYTAJ O CENĘ",
    PRICES = "ZAPYTAJ O CENY",
    RESERVATION = "Rezerwacja",
    ARRANGE_MEETING = "UMÓW SIĘ NA SPOTKANIE",
    INVESTMENT = "ZAPYTAJ DEWELOPERA O OFERTĘ",
    PROMOTIONS = "KONTAKT Z DEWELOPEREM",
    VENDOR = "ZAPYTAJ DEWELOPERA"
}
